export const getClip = (position = 'tl', width = '50') => {
    if (!position || !width) return null;

    return position === 'tr'
        ? `polygon(0 15px, calc(100% - ${width}%) 15px, calc(100% - ${width}%) 0, 100% 0, 100% 100%, 0 100%)`
        : position === 'tl'
        ? `polygon(0 0, ${width}% 0, ${width}% 15px, 100% 15px, 100% 100%, 0 100%)`
        : position === 'bl'
        ? `polygon(0 0, 100% 0, 100% calc(100% - 15px), ${width}% calc(100% - 15px), ${width}% 100%, 0 100%)`
        : `polygon(100% 0, 100% 100%, calc(100% - ${width}%) 100%, calc(100% - ${width}%) calc(100% - 15px), 0 calc(100% - 15px), 0 0)`;
};

export const getAnimatedClip = (position = 'tl', width = '50') => {
    if (!position || !width) return null;

    return position === 'tr'
        ? [
              {
                  clipPath:
                      'polygon(0 15px, 0 15px, 0 0, 100% 0, 100% 100%, 0 100%)'
              },
              {
                  clipPath: `polygon(0 15px, calc(100% - ${width}%) 15px, calc(100% - ${width}%) 0, 100% 0, 100% 100%, 0 100%)`
              }
          ]
        : position === 'tl'
        ? [
              {
                  clipPath:
                      'polygon(0 0, 100% 0, 100% 15px, 100% 15px, 100% 100%, 0 100%)'
              },
              {
                  clipPath: `polygon(0 0, ${width}% 0, ${width}% 15px, 100% 15px, 100% 100%, 0 100%)`
              }
          ]
        : position === 'bl'
        ? [
              {
                  clipPath:
                      'polygon(0 0, 100% 0, 100% calc(100% - 15px), 100% calc(100% - 15px), 100% 100%, 0 100%)'
              },
              {
                  clipPath: `polygon(0 0, 100% 0, 100% calc(100% - 15px), ${width}% calc(100% - 15px), ${width}% 100%, 0 100%)`
              }
          ]
        : [
              {
                  clipPath:
                      'polygon(100% 0, 100% 100%, 0 100%, 0 calc(100% - 15px), 0 calc(100% - 15px), 0 0)'
              },
              {
                  clipPath: `polygon(100% 0, 100% 100%, calc(100% - ${width}%) 100%, calc(100% - ${width}%) calc(100% - 15px), 0 calc(100% - 15px), 0 0)`
              }
          ];
};
