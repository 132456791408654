import './job-preview.scss';

import { getAnimatedClip } from '../../utilities/js/clip';

class Image {
    constructor(element, options) {
        const settings = {
            initAttr: 'data-section'
        };

        this.settings = Object.assign({}, settings, options);
        this.$job = element;

        this.initialize();
    }

    initialize() {
        this.$job.animate(
            getAnimatedClip(
                this.$job.dataset.overlapPosition,
                this.$job.dataset.overlapWidth
            ),
            {
                duration: 400,
                easing: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
                fill: 'forwards'
            }
        );
    }
}

export { Image };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $jobs = $context.querySelectorAll('.clipped-border');
        for (let i = 0; i < $jobs.length; i++) {
            $jobs[i].API = new Image($jobs[i]);
        }
    }
});
